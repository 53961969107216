
import { Component, Vue } from 'vue-property-decorator'
import Product from '../components/Product.vue'
import Sale from '../components/Sale.vue'
import ContactsTable from '../components/ContactsTable.vue'

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, LineChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
import VChart from 'vue-echarts'

use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent, LineChart, GridComponent])

@Component({ components: { Product, ContactsTable, Sale, VChart } })
export default class Shop extends Vue {
  shop: yakkyo.IShopMonitor = null
  products: yakkyo.IShopMonitorProduct[] = []
  productParams = {
    totalPages: 0,
    page: 1,
    itemsPerPage: 12
  }

  loading = false

  generalHeaders = [
    { text: 'Category', value: 'Category', width: '200px' },
    { text: 'Tech Spend USD', value: 'Tech Spend USD', width: '200px' },
    { text: 'Sales Revenue USD', value: 'Sales Revenue USD', width: '100px' },
    { text: 'Tranco', value: 'Tranco', width: '250px' },
    { text: 'Page Rank', value: 'Page Rank', width: '250px' },
    { text: 'Majestic', value: 'Majestic', width: '150px' },
    { text: 'Umbrella', value: 'Umbrella', width: '150px' },
    { text: 'Exclusion', value: 'Exclusion', width: '150px' },
    { text: 'GDPR', value: 'GDPR', width: '150px' }
  ]

  scrapingHeaders = [
    { text: 'Date', value: 'date', width: '200px' },
    { text: 'Updated Products', value: 'updatedProducts.length', width: '200px' },
    { text: 'Updated Variants', value: 'updatedVariants.length', width: '100px' }
  ]

  get chartOptions() {
    return {
      tooltip: { show: false },
      title: {
        text: `Total orders by country`,
        subtext: 'A breakdown of the orders, aggregated by country',
        left: 'center'
      },
      legend: {
        show: false
      },
      labelLine: {
        length: 30
      },
      label: {
        formatter: '  {b|{b}}  \n{hr|}\n {c} sales  {per|{d}%}  ',
        backgroundColor: '#f0f2f5',
        borderColor: '#f0f2f5',
        borderWidth: 1,
        borderRadius: 4,
        rich: {
          a: {
            color: '#6E7079',
            lineHeight: 22,
            align: 'left'
          },
          hr: {
            borderColor: '#8C8D8E',
            width: '100%',
            borderWidth: 0,
            height: 0
          },
          b: {
            color: '#4C5058',
            fontSize: 14,
            fontWeight: 'bold',
            lineHeight: 33,
            align: 'left'
          },
          per: {
            color: '#fff',
            backgroundColor: '#4C5058',
            borderColor: '#8C8D8E',
            padding: [3, 4],
            borderRadius: 4,
            lineHeight: 22
          }
        }
      },
      series: [
        {
          name: 'Sales by Country',
          type: 'pie',
          radius: ['40%', '60%'],
          avoidLabelOverlap: true,
          data:
            (this.shop as any).salesByCountry && (this.shop as any).salesByCountry.length
              ? (this.shop as any).salesByCountry.map(sbc => ({ value: sbc.orders, name: sbc.countryCode }))
              : []
        }
      ]
    }
  }

  get staffItems() {
    if (!this.shop) return []
    return this.shop.contacts
  }

  async created() {
    try {
      this.loading = true
      const response = await this.$store.state.apiClient.getShop(this.$route.params.id)
      this.shop = response.data

      await this.getProducts()
    } catch (err) {
      console.log("couldn't get shops data")
    } finally {
      this.loading = false
    }
  }

  async getProducts() {
    try {
      this.loading = true
      const response = await this.$store.state.apiClient.getShopProducts(this.$route.params.id, this.productParams)
      const { products, total, itemsPerPage } = response.data

      this.productParams.totalPages = Math.ceil(total / itemsPerPage)
      this.products = products
    } catch (err) {
      console.log("couldn't get shop products")
    } finally {
      this.loading = false
    }
  }
}
