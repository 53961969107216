
import { Component, Vue, Prop } from 'vue-property-decorator'
import Variant from './Variant.vue'

@Component({ components: { Variant } })
export default class Product extends Vue {
  @Prop({ required: true }) product!: yakkyo.IShopMonitorProduct
  @Prop({ default: false }) authenticated!: boolean

  show = false
  loading = false

  snackbarText = 'something'
  snackbarColor = 'green'
  snackbar = false

  get price() {
    const prices = this.product.variants.map(v => parseFloat(v.price))
    const max = Math.max(...prices)
    const min = Math.max(...prices)

    if (min === max) return max
    return `${min}-${max}`
  }

  get isYakkyoProduct() {
    const variantSkus = this.product.variants.map(v => v.sku)
    return variantSkus.some(sku => sku && sku.length > 13 && /^YK[0-9]+-[a-z0-9]/i.test(sku))
  }

  async linkImageSearch(search: yakkyo.IImageSearch) {
    try {
      await this.$store.state.apiClient.linkImageSearch(this.product._id, search._id)
    } catch (error) {
      console.log('Image search failed to link')
    }
  }

  async startImageSearch() {
    // i know, its horrible
    if (this.product.imageSearch)
      return (window.location.href = 'https://app.yakkyofy.com/quotations/image-search/' + this.product.externalId)

    this.loading = true
    try {
      const { data } = await this.$store.state.apiClient.startImageSearch({
        urls: this.product.images,
        title: this.product.title,
        sourceUrl: this.product.url,
        sourceId: this.product.externalId
      })

      if (!data.success) throw new Error(data.error || 'Eww, something went wrong!')

      this.snackbarText = '✔ Image search started successfully'
      this.snackbarColor = 'green'

      await this.linkImageSearch(data.search)
      this.$emit('refresh-list')
    } catch (err) {
      this.snackbarText = '✘ Image search failed to start'
      this.snackbarColor = 'red'
    }
    this.snackbar = true
    this.loading = false
  }
}
