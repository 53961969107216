
import { Component, Vue, Watch } from 'vue-property-decorator'

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { LineChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components'
import VChart from 'vue-echarts'

import { debounce } from 'lodash'
use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  LineChart,
  GridComponent,
  ToolboxComponent,
  DataZoomComponent
])

@Component({ components: { VChart } })
export default class Sale extends Vue {
  loading = false

  params = {} as any
  products = []
  sales = []

  modal = false

  dates = []
  date = ''

  search = null

  debounceGetSales = debounce(this.getSales, 1000)
  debounceGetProducts = debounce(this.getProducts, 1000)

  get salesDates() {
    return this.sales.map(s => s._id)
  }

  get salesOrders() {
    return this.sales.map(s => s.orders)
  }

  get salesRevenue() {
    return this.sales.map(s => s.revenue.toFixed(2))
  }

  get salesChartOptions() {
    const baseSeries = { type: 'line', smooth: true, symbolSize: 8 }
    const baseAxis = { type: 'category', boundaryGap: false, axisLine: { onZero: true } }

    return {
      title: {
        text: 'Sales vs Revenue',
        subtext: 'A breakdown of the sales and revenue by week',
        left: 'center'
      },
      tooltip: { trigger: 'axis', axisPointer: { type: 'shadow', label: { show: true } } },
      legend: { data: ['Orders', 'Revenue'], left: 10 },
      toolbox: { feature: { dataZoom: { yAxisIndex: 'none' }, saveAsImage: {} } },
      grid: [
        { left: 60, right: 50, height: '35%' },
        { left: 60, right: 50, top: '55%', height: '35%' }
      ],
      dataZoom: [
        { show: true, realtime: true, start: 0, end: 100, xAxisIndex: [0, 1] },
        { type: 'inside', realtime: true, start: 0, end: 100, xAxisIndex: [0, 1] }
      ],
      axisPointer: { link: [{ xAxisIndex: 'all' }] },
      xAxis: [
        { ...baseAxis, data: this.salesDates },
        { ...baseAxis, gridIndex: 1, data: this.salesDates, position: 'top' }
      ],
      yAxis: [
        { name: 'Orders', type: 'value' },
        { gridIndex: 1, name: 'Revenue(USD)', type: 'value', inverse: true }
      ],
      series: [
        { data: this.salesOrders, name: 'Orders', ...baseSeries },
        { data: this.salesRevenue, xAxisIndex: 1, yAxisIndex: 1, name: 'Revenue', ...baseSeries }
      ]
    }
  }

  @Watch('search')
  onSearchUpdate() {
    this.debounceGetProducts()
  }

  updateDateFilter(data: string[]) {
    this.params.end = data[0]
    this.params.start = data[1]
    this.date = this.dates.join(' ~ ')
    this.debounceGetSales()
  }

  updateProductFilter(productId: string) {
    this.params.product = productId
    this.debounceGetSales()
  }

  async created() {
    try {
      await this.getSales()
    } catch (err) {
      console.log("couldn't get shops data")
    }
  }

  async getProducts() {
    try {
      this.loading = true
      const response = await this.$store.state.apiClient.getShopProducts(this.$route.params.id, { title: this.search })
      const { products } = response.data

      this.products = products
    } catch (err) {
      console.log("couldn't get shop products")
    } finally {
      this.loading = false
    }
  }

  async getSales() {
    try {
      this.loading = true
      const { data } = await this.$store.state.apiClient.getShopSales(this.$route.params.id, this.params)
      this.sales = data
    } catch (err) {
      console.log("couldn't get shop products")
    } finally {
      this.loading = false
    }
  }
}
